<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <div class="fibra-titulo-fijo">Líneas móviles</div>
        <div class="fibra-titulo-lineas">
          Todas ellas con llamadas ilimitadas
        </div>
      </v-col>
      <v-col cols="2" class="linea_swich">
        <v-switch v-model="opcionswitch" inset></v-switch>
      </v-col>
    </v-row>
    <contrato v-show="opcionswitch"></contrato>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import contrato from './lineaMovil.vue'

export default {
    components: { contrato },

    data: () => ({
        opcionswitch: false
    }),
    computed: {
        Vswitch () {
            return this.opcionswitch
        }
    },
    watch: {
        Vswitch (value) {
            this.$store.dispatch('setswitchLineasMovil', value)
        }
    },
    methods: {}
}
</script>
